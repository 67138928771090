import React, {FunctionComponent, useCallback, useEffect, useRef, useState} from 'react';
import TextareaAutosize from 'react-textarea-autosize';
import {FormattedMessage, useIntl} from 'react-intl';
import cn from 'classnames';

import {loadArticleAction} from 'redux/content/contentAsyncActions';
import {useDispatch} from 'react-redux';
import {closeArticle} from 'redux/content/actions';

import GritxButton from 'components/gritx-button';
import Loader from 'components/loader';
import {ButtonVariant} from 'components/gritx-button/ButtonVariantEnum';
import {BotActionType, BotType, IBotAction, IBotAnswer, IXpedition, MessageType} from 'utils/hooks/use-chat-bot/interfaces';
import {ReactComponent as EmptyHeart} from 'assets/image/library/empty-heart.svg';

import {useChatBot} from 'utils/hooks/use-chat-bot/useChatBot';

import {XpeditionList} from './xpedition/XpeditionList';
import {BotContent} from './BotContent';
import {BotLoader} from './BotLoader';
import {UserMessage} from './UserMessage';
import {BotMessages} from './BotMessages';

import {IListComponentData, IAutocompleteComponentData, IOverlayRadio, IOverlaySuggestion} from 'components/overlay/interfaces';
import {BotAnswer} from './botAnswer';
import {BotAutocomplete} from './BotAutocomplete';

import './styles.scss';
import Debounce from 'lodash/debounce';
import ChatLoader from './chat-loader';
import {BinahModal, VitalSigns} from '../binah-modal/BinahModal';

interface IBotChat {
  mode: BotType,
  onChangeBreadcrumbs?: (xpedition: IXpedition) => void
}

export const BotChat: FunctionComponent<IBotChat> = ({
  mode,
  onChangeBreadcrumbs
}: IBotChat) => {
  const intl = useIntl();
  const chatLogRef = useRef<HTMLDivElement>(null);
  const refInput = useRef<HTMLTextAreaElement>();
  const [groupAnswers, setGroupAnswers] = useState<IBotAnswer[]>([]);
  const [singleAnswers, setSingleAnswers] = useState<BotAnswer[]>([]);
  const [sendMessage, setSendMessage] = useState<string>();
  const [input, setInput] = useState<string>('');
  const [showMessages, setShowMessages] = useState(false);
  const [hideUserMessage, setHideUserMessage] = useState(false);
  const [timer, setTimer] = useState<NodeJS.Timeout>();
  const [showLoading, setShowLoading] = useState(false);
  // -----xpedition----
  const [isShowXpeditionOverlay, setIsShowXpeditionOverlay] = useState(false);
  const [xpeditionOverlayAnswer, setXpeditionOverlayAnswer] = useState<IBotAction | null>(null);
  const [suggester, setSuggester] = useState<IListComponentData | null>(null);
  const [isAutoSendOverlay, setIsAutoSendOverlay] = useState(false);
  const [autocomplete, setAutocomplete] = useState<IAutocompleteComponentData | null>(null);
  const [autocompleteSuggestions, setAutocompleteSuggestions] = useState<IOverlaySuggestion[] | null>(null);
  const [showAutocompletePopup, setShowAutocompletePopup] = useState<boolean>(false);
  const [suggestionsLoading, setSuggestionsLoading] = useState<boolean>(false);
  const [binahActionButton, setBinahActionButton] = useState<IBotAction | null>(null);
  const [isShowBinahDialog, setIsShowBinahDialog] = useState(false);

  // ----------
  const chatBot = useChatBot(mode);
  const dispatch = useDispatch();

  function getDisabledInputStatus() {
    return suggester || autocomplete ? false : isShowXpeditionOverlay || Boolean(chatBot.botAnswer.find(answer => answer.keyboard?.buttons.length));
  }

  const scrollToBottom = () => {
    chatLogRef?.current?.scrollTo(0, chatLogRef.current.scrollHeight);
  };

  function hideUserMessageTimer(hasAnswers: boolean) {
    if (timer) {
      clearTimeout(timer);
      setTimer(undefined);
    }
    if (hasAnswers) {
      setTimer(setTimeout(() => setHideUserMessage(true), 3000));
    }
  }

  const pushUserMessage = (text: string) => {
    setInput('');
    setSendMessage('');
    setHideUserMessage(false);

    chatBot.sendMessage(MessageType.Text, {text});
    setTimeout(() => setSendMessage(text), 100);
    setTimeout(() => setShowLoading(true), Math.floor(Math.random() * 1000) + 300);
  };

  function handleSendOverlay() {
    setIsShowXpeditionOverlay(false);
    setIsAutoSendOverlay(false);

    if (!xpeditionOverlayAnswer) {
      return;
    }
    const actionButton = xpeditionOverlayAnswer;

    setXpeditionOverlayAnswer(null);
    chatBot.sendMessage(MessageType.Action, {
      button: {
        actionType: actionButton.actionType,
        actionBody: actionButton.actionBody,
        actionUrl: actionButton.actionUrl,
        serialNumber: actionButton.serialNumber,
        text: actionButton.text
      }
    });
  }

  const handleSend = () => {
    if (isShowXpeditionOverlay) {
      setSuggester(null);
      setInput('');
      dispatch(closeArticle());
      handleSendOverlay();
      setAutocomplete(null);
      setAutocompleteSuggestions(null);
    } else {
      pushUserMessage((input as string).trim());
    }
  };

  useEffect(() => {
    setIsShowXpeditionOverlay(false);
    setIsAutoSendOverlay(false);
    if (chatBot.botAnswer.length || sendMessage) {
      setShowMessages(true);
    }
    setShowLoading(false);

    hideUserMessageTimer(chatBot.botAnswer.length !== 0);

    // setGroupAnswers(chatBot.botAnswer.filter(answer => answer.contentType));
    const contentAnswers = chatBot.botAnswer
      .filter(answer => answer.contentId);

    if (contentAnswers.length) {
      const contentId = contentAnswers[contentAnswers.length - 1].contentId;

      if (contentId) {
        dispatch(loadArticleAction(contentId.toString()));
      }
    }

    const singleAnswerList = chatBot.botAnswer
      .filter(answer => !answer.contentType)
      .map((answer) => {
        const newAnswer = new BotAnswer(answer);
        const listComponent = newAnswer.getOverlayListComponentData();

        if (listComponent) {
          setSuggester(listComponent);
        }

        const autocompleteComponent = newAnswer.getOverlayAutocompleteComponentData();

        if (autocompleteComponent) {
          setAutocomplete(autocompleteComponent);
        }

        return newAnswer;
      });

    setSingleAnswers(singleAnswerList);
  }, [chatBot.botAnswer, sendMessage]);

  useEffect(() => {
    if ((chatBot.isLoading && showLoading) || chatBot.botAnswer) {
      scrollToBottom();
    }
  }, [chatBot.isLoading, showLoading, chatBot.botAnswer]);
  useEffect(() => {
    if (isAutoSendOverlay) {
      handleSend();
    }
  }, [isAutoSendOverlay]);

  useEffect(() => {
    if (mode === BotType.Xpedition || mode === BotType.WA) {
      chatBot.xpedition.getXpeditionList();
    }
  }, []);

  const keydownHandler = (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      if (event.shiftKey) {
        const {
          selectionStart,
          selectionEnd
        } = event.currentTarget;

        const newValue = `${event.currentTarget.value?.substring(0, selectionStart)}\n${event.currentTarget.value?.substring(selectionEnd)}`;

        setInput(newValue);
        if (refInput.current) {
          refInput.current.value = newValue;
          refInput.current.selectionStart = selectionStart + 1;
          refInput.current.selectionEnd = selectionStart + 1;
        }
      } else if (!getButtonDisabledStatus()) {
        handleSend();
      }
    }
  };

  function handleHelp() {
    pushUserMessage('Help');
  }

  function handleClickAction(actionButton: IBotAction) {
    const actionBody = JSON.parse(actionButton.actionBody);

    if (actionBody.apiButton === 'MEASUREMENT') {
      setBinahActionButton(actionButton);
      setIsShowBinahDialog(true);

      return;
    }

    switch (actionButton.actionType) {
      case BotActionType.ContentView:
        dispatch(loadArticleAction(actionBody.addActionBody.contentInfo.id));
        setSendMessage('');
        setIsShowXpeditionOverlay(false);
        setIsAutoSendOverlay(false);

        chatBot.sendMessage(MessageType.Action, {
          button: {
            actionType: actionButton.actionType,
            actionBody: actionButton.actionBody,
            actionUrl: actionButton.actionUrl,
            serialNumber: actionButton.serialNumber,
            text: actionButton.text
          }
        });
        break;
      case BotActionType.OpenURL:
      case BotActionType.ReturnValue:
      case BotActionType.Schedule:
        setSendMessage('');
        setIsShowXpeditionOverlay(false);
        setIsAutoSendOverlay(false);

        chatBot.sendMessage(MessageType.Action, {
          button: {
            actionType: actionButton.actionType,
            actionBody: actionButton.actionBody,
            actionUrl: actionButton.actionUrl,
            serialNumber: actionButton.serialNumber,
            text: actionButton.text
          }
        });
        break;
      default:
        break;
    }
  }

  function handleStartXpedition(xpeditionData: { botId: number, xpedition: IXpedition }) {
    window.scrollTo(0, 0);
    chatBot.xpedition.startXpedition(xpeditionData.botId);
    if (onChangeBreadcrumbs) {
      onChangeBreadcrumbs(xpeditionData.xpedition);
    }
  }

  // todo remove
  async function handleRemoveDialog(dialogId: number) {
    await chatBot.dialogMethods.removeDialog(dialogId);
    chatBot.xpedition.getXpeditionList();
  }

  // ----------

  function handleChangeOverlayAnswer(answer: IBotAction | null, autoSend?: boolean) {
    setIsShowXpeditionOverlay(true);
    setIsAutoSendOverlay(autoSend || false);
    setInput('');
    setXpeditionOverlayAnswer(answer);
  }

  function handleChangeSuggestion(suggestion: IOverlayRadio) {
    return () => {
      setInput(suggestion.label);
      if (suggester) {
        const updatedSuggester = suggester;

        updatedSuggester.inputComponent.value = suggestion.value;

        if (updatedSuggester?.componentOverlay.parsedActionBody?.addActionBody.form) {
          updatedSuggester.componentOverlay
            .parsedActionBody
            .addActionBody.form
            .controls[updatedSuggester.controlIdx]
            .inputs[updatedSuggester.inputIdx] = suggester.inputComponent;
        }
        setSuggester(updatedSuggester);
        const userAnswer = {
          ...updatedSuggester.componentOverlay,
          actionBody: JSON.stringify(updatedSuggester.componentOverlay.parsedActionBody)
        };

        delete userAnswer.parsedActionBody;
        setXpeditionOverlayAnswer(userAnswer);
        setIsShowXpeditionOverlay(true);
      }
    };
  }

  const handleChangeAutocompleteSuggestion = (suggestion: IOverlaySuggestion) => {
    setInput(suggestion.name);
    if (autocomplete) {
      const updatedAutocomplete = autocomplete;

      updatedAutocomplete.inputComponent.value = suggestion.name;

      if (updatedAutocomplete?.componentOverlay.parsedActionBody?.addActionBody.form) {
        updatedAutocomplete.componentOverlay
          .parsedActionBody
          .addActionBody.form
          .controls[updatedAutocomplete.controlIdx]
          .inputs[updatedAutocomplete.inputIdx] = autocomplete.inputComponent;
      }
      setAutocomplete(updatedAutocomplete);
      const userAnswer = {
        ...updatedAutocomplete.componentOverlay,
        actionBody: JSON.stringify(updatedAutocomplete.componentOverlay.parsedActionBody)
      };

      delete userAnswer.parsedActionBody;
      setXpeditionOverlayAnswer(userAnswer);
      setIsShowXpeditionOverlay(true);
      setAutocompleteSuggestions(null);
      setShowAutocompletePopup(false);
    }
  };

  const fillAutocompleteSuggestions = useCallback(Debounce((autocompleteData: IAutocompleteComponentData, search: string) => {
    const minQueryLength = autocompleteData.inputComponent.request?.minQueryLength === 0 ? 0 : autocompleteData.inputComponent.request?.minQueryLength || 3;

    if (!(search.length >= minQueryLength && autocompleteData && autocompleteData.inputComponent.value.length === 0
      && autocompleteData.inputComponent.request)) {
      return;
    }
    const params = autocompleteData.inputComponent.request.requestParams?.reduce((data, item) => {
      return {
        ...data,
        [item.code]: (item.input ? search.toUpperCase() : undefined) || item.default
      };
    }, {});

    setSuggestionsLoading(true);
    chatBot.dialogMethods.getAutocompleteDictionary(autocompleteData.inputComponent.request.serviceUrl, params)
      .then(dialogList => {
        setAutocompleteSuggestions(dialogList);
        setSuggestionsLoading(false);
        setShowAutocompletePopup(true);
      });
  }, 300), []);

  function handleCloseBinah() {
    setIsShowBinahDialog(false);
  }

  function handleCloseBinahWithMeasurements(measurements: VitalSigns) {
    setIsShowBinahDialog(false);
    if (binahActionButton == null) {
      return;
    }
    const actionBody = JSON.parse(binahActionButton.actionBody);

    actionBody.addActionBody.measurement.heartRate = measurements.heartRate?.value;
    actionBody.addActionBody.measurement.breathingRate = measurements.breathingRate?.value;
    actionBody.addActionBody.measurement.bloodPressureSystolic = measurements.bloodPressure?.value?.systolic;
    actionBody.addActionBody.measurement.bloodPressureDiastolic = measurements.bloodPressure?.value?.diastolic;
    actionBody.addActionBody.measurement.oxygenSaturation = measurements.oxygenSaturation?.value;
    actionBody.addActionBody.measurement.stressLevel = measurements.stressLevel?.value;
    actionBody.addActionBody.measurement.stressIndex = measurements.stressIndex?.value;
    actionBody.addActionBody.measurement.meanRri = measurements.meanRri?.value;
    actionBody.addActionBody.measurement.rmssd = measurements.rmssd?.value;
    actionBody.addActionBody.measurement.hrvSdnn = measurements.sdnn?.value;
    actionBody.addActionBody.measurement.sd1 = measurements.sd1?.value;
    actionBody.addActionBody.measurement.sd2 = measurements.sd2?.value;
    actionBody.addActionBody.measurement.prq = measurements.prq?.value;
    actionBody.addActionBody.measurement.pnsIndex = measurements.pnsIndex?.value;
    actionBody.addActionBody.measurement.pnsZone = measurements.pnsZone?.value;
    actionBody.addActionBody.measurement.snsIndex = measurements.snsIndex?.value;
    actionBody.addActionBody.measurement.snsZone = measurements.snsZone?.value;
    actionBody.addActionBody.measurement.wellnessLevel = measurements.wellnessLevel?.value;
    actionBody.addActionBody.measurement.wellnessIndex = measurements.wellnessIndex?.value;

    setSendMessage('');
    setIsShowXpeditionOverlay(false);
    setIsAutoSendOverlay(false);
    chatBot.sendMessage(MessageType.Action, {
      button: {
        actionType: binahActionButton.actionType,
        actionBody: JSON.stringify(actionBody),
        actionUrl: binahActionButton.actionUrl,
        serialNumber: binahActionButton.serialNumber,
        text: binahActionButton.text
      }
    });
  }

  if (mode === BotType.Xpedition && !chatBot.currentDialog) {
    if (chatBot.xpedition.xpeditionList.length === 0) {
      return <Loader nested/>;
    }
    const {xpeditionList} = chatBot.xpedition;

    return <XpeditionList
      xpeditionList={xpeditionList}
      onStart={handleStartXpedition}
      onRemove={handleRemoveDialog}
    />;
  }

  function getButtonDisabledStatus(): boolean {
    return isShowXpeditionOverlay ? !xpeditionOverlayAnswer : !input?.trim();
  }

  function getButtonTitle(): string {
    if (isShowXpeditionOverlay && xpeditionOverlayAnswer) {
      return intl.formatMessage({
        id: 'common.button.next',
        defaultMessage: 'Next'
      });
    }

    return intl.formatMessage({
      id: 'common.button.send',
      defaultMessage: 'Send'
    });
  }

  const renderInputArea = () => <>
    <div className="bot-chat__form">
      <TextareaAutosize
        ref={(tag: HTMLTextAreaElement) => {
          refInput.current = tag;
        }}
        className="bot-chat__input"
        maxLength={2000}
        placeholder={intl.formatMessage({
          id: 'gritx.chat.inputPlaceholder',
          defaultMessage: 'Type a message'
        })}
        maxRows={9}
        value={input}
        onKeyDown={keydownHandler}
        onChange={ev => {
          if (suggester) {
            const updatedSuggester = suggester;

            updatedSuggester.inputComponent.value = '';
            setSuggester(updatedSuggester);
            setXpeditionOverlayAnswer(null);
          }
          if (autocomplete) {
            const updatedAutocomplete = autocomplete;

            updatedAutocomplete.inputComponent.value = '';
            setSuggester(updatedAutocomplete);
            setXpeditionOverlayAnswer(null);
            setAutocompleteSuggestions(null);
            setShowAutocompletePopup(false);
            fillAutocompleteSuggestions(autocomplete, ev.target.value);
          }
          setInput(ev.target.value);
        }}
        onFocus={ev => {
          if (autocomplete) {
            fillAutocompleteSuggestions(autocomplete, ev.target.value);
          }
        }}
        onBlur={ev => {
          if (autocomplete && ev.relatedTarget?.className !== 'bot-chat__suggester-button') {
            setShowAutocompletePopup(false);
          }
        }}
        disabled={getDisabledInputStatus()}
      />
      <GritxButton
        title={getButtonTitle()}
        variant={ButtonVariant.Primary}
        disabled={getButtonDisabledStatus()}
        className="bot-chat__send-btn"
        onClick={handleSend}
      />
    </div>
    {
      input.length >= 3 && suggester && suggester.inputComponent.value.length === 0
      && suggester.inputComponent.list
      && <ul className="bot-chat__suggester">
        {
          suggester.inputComponent.list
            .filter(item => item.value.toUpperCase()
              .includes(input.toUpperCase()))
            .map(item => <li key={item.id} className="bot-chat__suggester-item">
              <button
                className="bot-chat__suggester-button"
                onClick={handleChangeSuggestion(item)}
                dangerouslySetInnerHTML={{
                  __html: item.label.replace(input.toUpperCase(), `<b>${input.toUpperCase()}</b>`)
                }}
              />
            </li>)
        }
      </ul>
    }
    { showAutocompletePopup && autocompleteSuggestions && <BotAutocomplete
      handleChangeAutocompleteSuggestion={handleChangeAutocompleteSuggestion}
      input={input}
      suggestions={autocompleteSuggestions}/>}
    { suggestionsLoading && <ChatLoader/> }
  </>;

  return <div className="bot">
    <div className="bot__wrapper">
      <div className="bot__window">
        <div className="bot-chat">
          <div className="bot-chat__toolbar">
            <EmptyHeart
              className={cn('bot-chat__bot-avatar', {
                'bot-chat__bot-avatar--hide': mode === BotType.Xpedition || mode === BotType.WA
              })}
            />
            {
              mode !== BotType.Xpedition
              && <GritxButton
                title={intl.formatMessage({
                  id: 'chat.helpButton',
                  defaultMessage: 'Help'
                })}
                variant={ButtonVariant.Outline}
                className="bot-chat__help-btn"
                onClick={handleHelp}
              />
            }
          </div>
          {showMessages
            ? <div className="bot-chat__wrapper bot-chat__log" ref={chatLogRef}>
              <UserMessage sendMessage={sendMessage} hideUserMessage={hideUserMessage}/>
              <BotMessages
                groupAnswers={groupAnswers}
                singleAnswers={singleAnswers}
                onClickAction={handleClickAction}
                onChangeOverlayAnswer={handleChangeOverlayAnswer}
              />
              <BotLoader isLoading={chatBot.isLoading && showLoading}/>
            </div>
            : <div className="bot-chat__wrapper">
              <div className="bot-chat__start-message">
                <FormattedMessage
                  id={'xpedition.message'}
                  defaultMessage={'We\'re here to help you take care of all your healthcare needs.'
                    + ' You can tell me what you\'re looking for or select a topic and I will guide you through the process. How can I help you today?'}
                />
              </div>
            </div>
          }
          {renderInputArea()}
        </div>
      </div>
      <div className="bot__content">
        <BotContent
          mode={mode}
          xpeditionList={chatBot.xpedition.xpeditionList}
          currentDialog={chatBot.currentDialog}
          botAnswer={singleAnswers}
          onChange={handleChangeOverlayAnswer}
          onStart={handleStartXpedition}
          onRemove={handleRemoveDialog}
        />
      </div>
      <BinahModal
        isOpen={isShowBinahDialog}
        onHide={handleCloseBinah}
        onCloseWithMeasurements={handleCloseBinahWithMeasurements}/>
    </div>
  </div>;
};
